<template lang="pug">
  v-tabs(v-model="activeTab" background-color="transparent" show-arrows)
    v-tab(
      v-for="tab in tabs"
      :key="tab.name"
      @click="switchTab(tab.name)"
      :disabled="disabled"
    )
      div
        div {{ tab.label }} ({{ tab.count }})
</template>

<script>
import { CANDIDATE_PAGE } from '../../candidates/core/candidates-const'
import { mapState } from "vuex"

export default {
  props: {
    candidatesCount: Object
  },

  data: () => ({
    activeTab: 0
  }),
  computed: {
    ...mapState('cm', {
      isMultipleMode: state => state.isMultipleMode,
    }),
    tabs() {
      return [
        {
          name: CANDIDATE_PAGE.RESERVED_ALL,
          label: 'Reserved',
          count: this.candidatesCount.reservedAll
        },
        {
          name: CANDIDATE_PAGE.TOB_CBR_RESERVE_QUEUE,
          label: 'Reserve queue',
          count: this.candidatesCount.topСbrReserveQueue
        },
        {
          name: CANDIDATE_PAGE.TOB_CBR_EXCHANGE_QUEUE,
          label: 'Exchange queue',
          count: this.candidatesCount.topСbrExchangeQueue
        },
        {
          name: CANDIDATE_PAGE.MIJN_CBR_QUEUE,
          label: 'MIJNCBR',
          count: this.candidatesCount.mijnCbrQueue
        }
      ]
    },
    disabled() {
      if (!this.$route.query.course_id || !this.$route.query.course_id.length) return true
      return false
    }
  },

  created() {
    this.setTabByQuery()
  },

  methods: {
    async switchTab(name) {
      if (this.$route.query.page_name === name) return
      await this.$router.replace({
        query: {
          ...this.$route.query,
          page_name: name,
          page: 1
        }
      })
      this.$emit('changed')
    },

    setTabByQuery() {
      let name = this.$route.query.page_name
      let tabIndex = this.tabs.findIndex(tab => tab.name === name)
      if (tabIndex < 0) return
      this.activeTab = tabIndex
    }
  },

  watch: {
    isMultipleMode: {
      handler: async function (isMultiple) {
        if (!isMultiple) return
        let oldTab = this.activeTab
        this.activeTab = this.tabs.findIndex(tab => tab.name === CANDIDATE_PAGE.TOB_CBR_RESERVE_QUEUE)
        try {
          await this.$router.replace({
            query: {
              ...this.$route.query,
              page_name: CANDIDATE_PAGE.TOB_CBR_RESERVE_QUEUE,
              page: 1
            }
          })
        } catch (error) {

        }
        if (this.$route.query.course_id && this.$route.query.course_id.length) {
          if (oldTab !== this.activeTab) this.$emit('changed')
          return
        }
        this.switchTab(CANDIDATE_PAGE.TOB_CBR_RESERVE_QUEUE)
      },
      immediate: true // idk why, but without it not triggered watcher at first time when mode change
    },

    '$route.query.page_name': function (newVal, oldVal) {
      this.setTabByQuery()
    }
  }
}
</script>
